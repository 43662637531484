import React, {useState,useEffect, Fragment} from 'react'
import Spinner from './Spinner';
import ReactGA from 'react-ga';
import Alerta from './Alerta';

const Perfil = () => {
    let user_data = JSON.parse(localStorage.getItem('co_user_data'));
    useEffect( () => {
        ReactGA.initialize('GTM-5JDTRDH');
        ReactGA.pageview(window.location.pathname + window.location.search);
        apiCall();
    },[]);

    const[firstLoad, updateFirstLoad] = useState(true);

    const[perfil, updatePerfil] = useState({
        'pseudonimo': '',
        'nombre': '',
        'ap': '',
        'am': '',
        'sexo': '',
        'dia': '',
        'mes': '',
        'anio': '',
        'estado': '',
        'municipio': '',
        'correo': '',
        'facebook': '',
        'instagram': '',
        'twitter': '',
        'youtube': ''
    });

    const[archivos, updateArchivos] = useState({
        'identificacion': null,
        'comprobante': null
    });

    const[archivosUrl, updateArchivosUrl] = useState({
        'identificacion': '',
        'comprobante': ''
    });

    const[editandoPerfil, updateEditandoPerfil] = useState(false);
    const[editandoPago, updateEditandoPago] = useState(false);
    const[estados,updateEstados] = useState([]);
    const[municipios,updateMunicipios] = useState([]);

    const[pago,updatePago] = useState({
        'clabe': '',
        'banco': '',
        'titular': '',
        'url_identificacion': '',
        'url_comprobante': '',
    });

    const[actualizandoPerfil,updateActualizacionPerfil] = useState(false);
    const[actualizandoPago,updateActualizacionPago] = useState(false);

    const[mostrarPopup, showPopup] = useState(false);
    const[mensaje, updateMensaje] = useState(false);

    const ocultarPopup = () => {
        showPopup(false)
    }

    const updateFields = (e) => {
        updatePerfil({
            ...perfil,
            [e.target.name]: e.target.value
        })
    }

    const updateFieldsPago = (e) => {
        // updateError(false)
        updatePago({
            ...pago,
            [e.target.name]: e.target.value
        })
    }

    const apiCall = async () => {
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/perfil/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            return;
        }
        updateEstados(response.estados)
        updateMunicipios(response.municipios)
        updatePerfil(response.perfil)
        if(response.perfil.nombre == ''){
            updateEditandoPerfil(true);
        }
        updatePago(response.pago)
        if(response.pago.clabe == ''){
            updateEditandoPago(true);
        }

        updateFirstLoad(false);
    }

    const updateEstadoMunicipios = async (e) => {
        updatePerfil({
            ...perfil,
            ['estado']: e.target.value
        });
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('estado_id',e.target.value);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/municipios/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            return;
        }
        updateMunicipios(response.municipios)
    }

    const guardarPerfil = async () => {
        if(perfil.nombre.trim().length === 0 || perfil.ap.trim().length === 0 || perfil.am.trim().length === 0 || perfil.correo.trim().length === 0 || perfil.pseudonimo.trim().length === 0){
                updateMensaje('Tu nombre completo, correo y pseudónimo son obligatorios.');
                showPopup(true);
                return;
        }
        let redes = 0;
        if(perfil.facebook != null && perfil.facebook.trim().length > 0){
            redes ++;
        }
        if(perfil.instagram != null && perfil.instagram.trim().length > 0){
            redes ++;
        }
        if(perfil.twitter != null && perfil.twitter.trim().length > 0){
            redes ++;
        }
        if(perfil.youtube != null && perfil.youtube.trim().length > 0){
            redes ++;
        }
        if(redes < 2){
            updateMensaje('Debes de ingresar por lo menos dos de tus redes sociales.');
            showPopup(true);
            return;
        }
        updateActualizacionPerfil(true);
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('pseudonimo',perfil.pseudonimo);
        formdata.append('nombre',perfil.nombre);
        formdata.append('ap',perfil.ap);
        formdata.append('am',perfil.am);
        if(perfil.sexo == null){
            formdata.append('sexo','');
        }else{
            formdata.append('sexo',perfil.sexo);
        }
        formdata.append('dia',perfil.dia);
        formdata.append('mes',perfil.mes);
        formdata.append('anio',perfil.anio);
        if(perfil.estado == null){
            formdata.append('estado','');
        }else{
            formdata.append('estado',perfil.estado);
        }
        if(perfil.municipio == null){
            formdata.append('municipio','');
        }else{
            formdata.append('municipio',perfil.municipio);
        }
        formdata.append('correo',perfil.correo);
        if(perfil.facebook == null){
            formdata.append('facebook','');
        }else{
            formdata.append('facebook',perfil.facebook);
        }
        if(perfil.instagram == null){
            formdata.append('instagram','');
        }else{
            formdata.append('instagram',perfil.instagram);
        }
        if(perfil.twitter == null){
            formdata.append('twitter','');
        }else{
            formdata.append('twitter',perfil.twitter);
        }
        if(perfil.youtube == null){
            formdata.append('youtube','');
        }else{
            formdata.append('youtube',perfil.youtube);
        }

        const api = await fetch(
            'https://www.editorialco.com/api/autor/guardarperfil/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        updateActualizacionPerfil(false);
        updateEditandoPerfil(false);
        if(!response.success){
            updateMensaje(response.mensaje);
            showPopup(true);
            return;
        }
    }

    const guardarPago = async () => {
        if(pago.clabe.trim() == '' || pago.banco.trim() == '' || pago.titular.trim() == ''){
                updateMensaje('Todos los campos son obligatorios.');
                showPopup(true);
                return;
        }
        updateActualizacionPago(true);
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('clabe',pago.clabe);
        formdata.append('banco',pago.banco);
        formdata.append('titular',pago.titular);
        if(archivos.identificacion != null){
            formdata.append('identificacion',archivos.identificacion);
        }
        if(archivos.comprobante != null){
            formdata.append('comprobante',archivos.comprobante);
        }

        const api = await fetch(
            'https://www.editorialco.com/api/autor/guardarpago/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );

        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            return;
        }
        updateArchivos({
            'identificacion': null,
            'comprobante': null
        });

        updatePago({
            ...pago,
            'url_identificacion': response.url_identificacion,
            'url_comprobante': response.url_comprobante
        });
        updateActualizacionPago(false);
        updateEditandoPago(false);
    }

    const archivoSeleccionado = (e) => {
        updateArchivos({
            ...archivos,
            [e.target.name]: e.target.files[0]
        });
        console.log(archivos.identificacion);
        console.log(archivos.comprobante);
    }

    var anios = []
    for(var i = (parseInt(new Date().getFullYear())-80); i <= (parseInt(new Date().getFullYear())-10); i++) {
        anios.push(i);
    }
    return (
        <div id="main-container" className="container">
            <Alerta
                mensaje={mensaje}
                mostrarPopup={mostrarPopup}
                ocultar={ocultarPopup}
            />
            {
                firstLoad ? 
                    <Spinner></Spinner>
                :
                <Fragment>
                    <div id="informacion-personal">
                        <h2>
                            Información Personal
                            {
                                editandoPerfil ?
                                null
                                :
                                <button className="btn btn-co perfil-editar" onClick={() => {updateEditandoPerfil(true)}}>Editar</button>
                            }
                        </h2>
                        {
                            editandoPerfil ? 
                            <Fragment>
                                <div className="form-group">
                                    <label htmlFor="nombre-perfil">Nombre legal</label>
                                    <span>Este es el nombre que aparece en tu documento oficial. Puede ser Licencia o Pasaporte</span>
                                    <input type="text" name="nombre" id="nombre-perfil" placeholder="Nombre" value={perfil.nombre} onChange={updateFields} />
                                    <input type="text" name="ap" id="ap-perfil" placeholder="Apellido Paterno" value={perfil.ap} onChange={updateFields} />
                                    <input type="text" name="am" id="am-perfil" placeholder="Apellido Materno" value={perfil.am} onChange={updateFields} />
                                    <div className="clear"></div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="pseudonimo-perfil">Pseudónimo</label>
                                    <span>Es el nombre que aparecerá en la portada de los libros</span>
                                    <input
                                        type="text"
                                        name="pseudonimo"
                                        id="pseudonimo"
                                        placeholder="Pseudónimo"
                                        value={perfil.pseudonimo}
                                        onChange={updateFields} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="sexo-perfil">Sexo</label>
                                    <select id="sexo-perfil" name="sexo" onChange={updateFields} value={perfil.sexo}>
                                        <option value="">Sexo</option>
                                        <option value="1">Hombre</option>
                                        <option value="2">Mujer</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="dia-perfil">Fecha de Nacimiento</label>
                                    <div className="clear"></div>
                                    <select id="dia-perfil" name="dia" onChange={updateFields} value={perfil.dia}>
                                        <option value=""></option>
                                        <option value="01" >01</option>
                                        <option value="02" >02</option>
                                        <option value="03" >03</option>
                                        <option value="04" >04</option>
                                        <option value="05" >05</option>
                                        <option value="06" >06</option>
                                        <option value="07" >07</option>
                                        <option value="08" >08</option>
                                        <option value="09" >09</option>
                                        <option value="10" >10</option>
                                        <option value="11" >11</option>
                                        <option value="12" >12</option>
                                        <option value="13" >13</option>
                                        <option value="14" >14</option>
                                        <option value="15" >15</option>
                                        <option value="16" >16</option>
                                        <option value="17" >17</option>
                                        <option value="18" >18</option>
                                        <option value="19" >19</option>
                                        <option value="20" >20</option>
                                        <option value="21" >21</option>
                                        <option value="22" >22</option>
                                        <option value="23" >23</option>
                                        <option value="24" >24</option>
                                        <option value="25" >25</option>
                                        <option value="26" >26</option>
                                        <option value="27" >27</option>
                                        <option value="28" >28</option>
                                        <option value="29" >29</option>
                                        <option value="30" >30</option>
                                        <option value="31" >31</option>
                                    </select>
                                    <select id="mes-perfil" name="mes" onChange={updateFields} value={perfil.mes}>
                                        <option value=""></option>
                                        <option value="01" >Enero</option>
                                        <option value="02" >Febrero</option>
                                        <option value="03" >Marzo</option>
                                        <option value="04" >Abril</option>
                                        <option value="05" >Mayo</option>
                                        <option value="06" >Junio</option>
                                        <option value="07" >Julio</option>
                                        <option value="08" >Agosto</option>
                                        <option value="09" >Septiempre</option>
                                        <option value="10" >Octubre</option>
                                        <option value="11" >Noviembre</option>
                                        <option value="12" >Diciembre</option>
                                    </select>
                                    <select id="anio-perfil" name="anio" onChange={updateFields} value={perfil.anio}>
                                        <option value=""></option>
                                        {
                                            anios.map(anio => (
                                                <option value={anio}>{anio}</option>
                                            ))
                                        }
                                    </select>
                                    <div className="clear"></div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="estado-perfil">Lugar de Residencia</label>
                                    <div className="clear"></div>
                                    <input type="text" className="" id="pais-perfil" value="México" disabled />
                                    <select id="estado-perfil" name="estado" onChange={updateEstadoMunicipios} value={perfil.estado}>
                                        <option value="">Estado</option>
                                        {
                                            estados.map(estado => (
                                                <option value={estado.id}>{estado.nombre}</option>
                                            ))
                                        }
                                    </select>
                                    <select id="municipio-perfil" name="municipio" onChange={updateFields} value={perfil.municipio}>
                                        <option>Municipio/Alcaldía</option>
                                        {
                                            municipios.map(municipio => (
                                                <option value={municipio.id}>{municipio.nombre}</option>
                                            ))
                                        }
                                    </select>
                                    <div className="clear"></div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="correo-perfil">Correo Electrónico</label>
                                    <input type="text" name="correo" id="correo-perfil" placeholder="Correo" value={perfil.correo} onChange={updateFields} />
                                    <div className="clear"></div>
                                </div>
                                <div className="form-group">
                                    <label>Redes Sociales</label>
                                    <input type="text" name="facebook" id="facebook" placeholder="Facebook" value={perfil.facebook} onChange={updateFields} />
                                    <input type="text" name="instagram" id="instagram" placeholder="Instagram" value={perfil.instagram} onChange={updateFields} />
                                    <input type="text" name="twitter" id="twitter" placeholder="Twitter" value={perfil.twitter} onChange={updateFields} />
                                    <input type="text" name="youtube" id="youtube" placeholder="Youtube" value={perfil.youtube} onChange={updateFields} />
                                    <div className="clear"></div>
                                </div>
                                {
                                    actualizandoPerfil ?
                                        <Spinner clase="spinner-small"></Spinner>
                                    :
                                        <button id="guardar-perfil" className="btn btn-co" onClick={guardarPerfil}>
                                            Guardar
                                        </button>
                                }
                                <div className="clear"></div>
                            </Fragment>
                            :
                            <Fragment>
                                <div className="form-group">
                                    <label htmlFor="nombre-perfil">Nombre legal</label>
                                    <span>Este es el nombre que aparece en tu documento oficial. Puede ser Licencia o Pasaporte</span>
                                    <div id="nombre-perfil">{perfil.nombre} {perfil.ap} {perfil.am}</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="nombre-perfil">Pseudónimo</label>
                                    <span>Es el nombre que aparecerá en la portada de los libros</span>
                                    <div id="pseudonimo-perfil">{perfil.pseudonimo}</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="sexo-perfil">Sexo</label>
                                    <div>
                                    {
                                        perfil.sexo == 1 ? 
                                            'Hombre':
                                        perfil.sexo == 2 ?
                                            'Mujer' :
                                            '-'
                                    }
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="dia-perfil">Fecha de Nacimiento</label>
                                    <div>
                                        {
                                            perfil.dia == '' ?
                                                '-':
                                                perfil.dia+'/'+perfil.mes+'/'+perfil.anio   
                                        }
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="estado-perfil">Lugar de Residencia</label>
                                    <div>
                                        México
                                        {
                                            estados.map(estado => {
                                                if(estado.id == perfil.estado){
                                                    return ', '+estado.nombre;
                                                }
                                            })
                                        }
                                        {
                                            municipios.map(municipio => {
                                                if(municipio.id == perfil.municipio){
                                                    return ', '+municipio.nombre;
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="correo-perfil">Correo Electrónico</label>
                                    <div>{perfil.correo}</div>
                                    <div className="clear"></div>
                                </div>
                                <div className="form-group">
                                    <label>Redes Sociales</label>
                                    <div>
                                        {
                                            perfil.facebook != null && perfil.facebook != '' ?
                                            <Fragment>
                                                <div className="red-social">
                                                    <img
                                                        className="icono-social"
                                                        src={"/img/facebook.png"}
                                                        alt="Facebook"
                                                        title="Facebook"
                                                    />{ perfil.facebook }
                                                </div>
                                            </Fragment>:
                                            null
                                        }
                                        {
                                            perfil.instagram != null && perfil.instagram != '' ?
                                            <Fragment>
                                                <div className="red-social">
                                                    <img
                                                        className="icono-social"
                                                        src={"/img/instagram.png"}
                                                        alt="Instagram"
                                                        title="Instagram"
                                                    />{ perfil.instagram }
                                                </div>
                                            </Fragment>:
                                            null
                                        }
                                       {
                                            perfil.twitter != null && perfil.twitter != '' ?
                                            <Fragment>
                                                <div className="red-social">
                                                    <img
                                                        className="icono-social"
                                                        src={"/img/twitter.png"}
                                                        alt="Twitter"
                                                        title="Twitter"
                                                    />{ perfil.twitter }
                                                </div>
                                            </Fragment>:
                                            null
                                        }
                                        {
                                            perfil.youtube != null && perfil.youtube != '' ?
                                            <Fragment>
                                                <div className="red-social">
                                                    <img
                                                        className="icono-social"
                                                        src={"/img/youtube.png"}
                                                        alt="YouTube"
                                                        title="YouTube"
                                                    />{ perfil.youtube }
                                                </div>
                                            </Fragment>:
                                            null
                                        }
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </Fragment>
                        }
                        
                    </div>
                    <div id="informacion-pago">
                        <h2>
                            Forma de Pago
                            {
                                editandoPago ?
                                null
                                :
                                <button className="btn btn-co perfil-editar" onClick={() => {updateEditandoPago(true)}}>Editar</button>
                            }
                        </h2>
                        <div id="leyenda-transferencia">
                            Transferencia Bancaria en MXN
                            <span>Recepción del pago: de 5 a 7 días laborales</span>
                            <span>El tiempo de procesamiento puede ser mayor debido a los fines de semana y días festivos</span>
                            <span>Ninguna comisión</span>
                        </div>
                        {
                            editandoPago ?
                                <Fragment>
                                    <div className="form-group">
                                        <label htmlFor="identificacion">Identificación oficial <span>INE o Pasaporte</span></label>
                                        <input type="file" name="identificacion" id="identificacion" accept=".pdf,.jpg,.png,.jpeg" onChange={archivoSeleccionado} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="identificacion">Comprobante de domicilio</label>
                                        <input type="file" name="comprobante" id="comprobante" accept=".pdf,.jpg,.png,.jpeg" onChange={archivoSeleccionado} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nombre-perfil">CLABE</label>
                                        <input type="text" name="clabe" id="cuenta-pago" placeholder="Cuenta CLABE" value={pago.clabe} onChange={updateFieldsPago} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nombre-perfil">Banco</label>
                                        <input type="text" name="banco" id="banco-pago" placeholder="Nombre del Banco" value={pago.banco} onChange={updateFieldsPago} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nombre-perfil">Titular</label>
                                        <input type="text" name="titular" id="titular-pago" placeholder="Nombre del titular de la cuenta" value={pago.titular} onChange={updateFieldsPago} />
                                    </div>
                                    {
                                        actualizandoPago ?
                                            <Spinner clase="spinner-small"></Spinner>
                                        :
                                            <button id="guardar-pago" className="btn btn-co" onClick={guardarPago}>
                                                Guardar
                                            </button>
                                    }
                                </Fragment>
                            :
                                <Fragment>
                                    <div className="form-group">
                                        <label htmlFor="nombre-perfil">Identificación oficial <span>INE o Pasaporte</span></label>
                                        {
                                            pago.url_identificacion ?
                                            <span><a href={ pago.url_identificacion } className="archivo-descarga btn btn-co" target="_blank" download>Descargar</a></span>
                                            :
                                            <span>No has guardado una identificación.</span>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nombre-perfil">Comprobante de domicilio</label>
                                        {
                                            pago.url_comprobante ?
                                            <span><a href={ pago.url_comprobante } className="archivo-descarga btn btn-co" target="_blank" download>Descargar</a></span>
                                            :
                                            <span>No has guardado una comprobante de domicilio.</span>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nombre-perfil">CLABE</label>
                                        <div>{pago.clabe}</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nombre-perfil">Banco</label>
                                        <div>{pago.banco}</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nombre-perfil">Titular</label>
                                        <div>{pago.titular}</div>
                                    </div>
                                </Fragment>
                        }
                        
                        <div className="clear"></div>
                    </div>
                </Fragment>
            }
        </div>
    )
}

export default Perfil