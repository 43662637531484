import $ from "jquery";
import React, {useState, useEffect, Fragment} from 'react'
import { BrowserRouter as Router, Route, Link, useRouteMatch, Switch } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReactHtmlParser from 'react-html-parser';
import AgregarPublicacion from './AgregarPublicacion';
import Publicacion from './Publicacion';
import Spinner from './Spinner';
import ReactGA from 'react-ga';
import Alerta from './Alerta';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import 'bootstrap';
import 'bootstrap/js/src/dropdown.js';
import 'react-summernote/dist/react-summernote.css';
import ReactSummernote from 'react-summernote';

const Publicaciones = () => {
    let user_data = JSON.parse(localStorage.getItem('co_user_data'));
    const [openDialog, setOpenDialog] = useState(false);
    const [mensajesDialog, setMensajesDialog] = useState([]);
    let { path, url } = useRouteMatch();
    const [data,updateData] = useState({});
    const[firstLoad, updateFirstLoad] = useState(true);
    const[delay, updateDelay] = useState(1000);
    const[mostrarPopup, showPopup] = useState(false);
    const[mensaje, updateMensaje] = useState(false);
    const[isOpen, updateisOpen] = useState(false);
    const[photoIndex, updatePhotoIndex] = useState(0);
    const[respuesta, updateRespuesta] = useState("");
    const[enviandoRespuesta, updateEnviandoRespuesta] = useState(false);
    const[mensajeVisible,updateMensajeVisible] = useState(0)
    const[subjectMensaje,updateSubjectMensaje] = useState(0)

    const ocultarPopup = () => {
        showPopup(false)
    }

    useEffect( () => {
        ReactGA.initialize('GTM-5JDTRDH');
        ReactGA.pageview(window.location.pathname + window.location.search);
        const interval = setInterval(() => {
            apiCall();
        }, delay);

        return () => clearInterval(interval);
    });

    const apiCall = async () => {
        updateDelay(60000);
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/publicaciones/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje(api.status+': Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateMensaje(response.mensaje);
            showPopup(true);
            return;
        }
        updateData(response);
        if(firstLoad == true){
            let primer_login = localStorage.getItem('co_primer_login');
            if(primer_login != null && primer_login == 1){
                updateisOpen(true);
                localStorage.removeItem('co_primer_login');
            }
        }
        updateFirstLoad(false);
    }

    const handleClose = () => {
        setOpenDialog(false);
        updateEnviandoRespuesta(false);
        updateRespuesta("");
    };

    const muestraMensaje = (mensaje) => {
        updateMensajeVisible(mensaje.id);
        updateSubjectMensaje(mensaje.subject)
        setMensajesDialog(mensaje.mensajes);
        setOpenDialog(true);
        marcarLeido(mensaje.id);
    }

    const marcarLeido = async (mensaje_id) => {
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('mensaje_id',mensaje_id);
        formdata.append('tipo',1);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/marcarleido/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            return;
        }

        const response = await api.json();
        if(response.success){
            updateData({
                ...data,
                ['mensajes']: response.mensajes
            });
        }
    }

    const updateStatus = (obra_id) => {
        let obras = data.obras;
        obras.map(obra => {
            if(obra.id == obra_id){
                obra.status = 'En Revisión';
            }
        });
        updateData({
            ...data,
            ['obras']: obras
        });
    }

    const eliminarObra = (obra_id) => {
        let obras = [...data.obras].filter((obra) => obra.id != obra_id);
        updateData({
            ...data,
            ['obras']: obras
        });
    }

    const ayudaEdicion = () => {
        updateisOpen(true);
    }

    const responderMensaje = async () => {
        if(respuesta.trim() === ""){
            updateMensaje("Debes de ingresar una respuesta antes de envíar.");
            showPopup(true);
            return;
        }
        updateEnviandoRespuesta(true);

        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('respuesta',respuesta.trim());
        formdata.append('mensaje_id',mensajeVisible);
        formdata.append('tipo',1);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/guardarrespuesta/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje(api.status+': Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        
        if(response.success){
            updateData({
                ...data,
                ['mensajes']: response.mensajes
            });
            let mensajes = response.mensajes.filter((mensaje) => mensaje.id === mensajeVisible);
            setMensajesDialog(mensajes[0].mensajes);
            updateRespuesta("");
            ReactSummernote.reset()
        }else{
            updateMensaje(response.mensaje);
            showPopup(true);
        }
        
        updateEnviandoRespuesta(false);
    }

    const initEditor = () => {
        $(".dropdown-toggle").dropdown();
        const editArea = document.querySelector(".note-editable");
    }

    return (
        <div id="main-container">
            <Alerta
                mensaje={mensaje}
                mostrarPopup={mostrarPopup}
                ocultar={ocultarPopup}
            />
            <Switch>
                <Route exact path={path}>
                    <div>
                        {
                            firstLoad ?
                                <Spinner></Spinner>
                            :
                            <Fragment>
                                <Dialog
                                    maxWidth="md"
                                    onClose={handleClose}
                                    open={openDialog}>
                                    <div className="titulo-dialogo">
                                        <h3>{ subjectMensaje }</h3>
                                        <IconButton aria-label="close" onClick={handleClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    <div className="contenido-dialogo">
                                        <div id="mensajes">
                                            {mensajesDialog.map(mensaje => (
                                                <Fragment>
                                                    <div className={"datos-mensaje mensaje-historial "+(mensaje.autor_mensaje === 1 ? 'admin' : 'autor')}>
                                                        <div>
                                                            <span className="fecha-mensaje">{ mensaje.fecha } |</span>
                                                            {
                                                                mensaje.autor_mensaje === 1 ?
                                                                    <span className="rol-usuario admin">Administrador</span>
                                                                :
                                                                    <span className="rol-usuario autor">Autor</span>
                                                            }
                                                        </div>
                                                        <div>{ ReactHtmlParser(mensaje.texto) }</div>
                                                    </div>
                                                    <div className="clear"></div>
                                                </Fragment>
                                            ))}
                                        </div>
                                        <ReactSummernote
                                                value=""
                                                options={{
                                                    height: 100,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['bold', 'italic', 'underline', 'clear']]
                                                    ]
                                                }}
                                                onChange={updateRespuesta}
                                                onInit={initEditor}
                                            />
                                        {
                                            enviandoRespuesta ? 
                                                <Spinner clase="spinner-small"></Spinner>
                                            :
                                                <button className="btn btn-co" id="enviar-respuesta" onClick={responderMensaje}>
                                                    Responder
                                                </button>
                                        }
                                    </div>
                                </Dialog>
                                <div className="container">
                                    <div className="bloque-publicaciones">
                                        <div id="manuscritos" className="bloque-contenido">
                                            <h2>Manuscritos <button className="ayuda" onClick={ayudaEdicion}>?</button></h2>
                                            <Link
                                                to={'/publicaciones/agregar'}
                                                id="nueva-publicacion"
                                            >
                                                Nueva publicación
                                            </Link>
                                            { data.obras !== undefined && data.obras.length > 0 ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Título</th>
                                                            <th className="centrado">Visualizaciones</th>
                                                            <th className="centrado">Precio</th>
                                                            <th className="centrado">Estatus</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.obras.map(obra => (
                                                            <tr key={"obra-"+obra.id}>
                                                                <td>
                                                                    <Link
                                                                        to={'/publicaciones/ver/'+obra.id}
                                                                    >
                                                                        {obra.nombre}
                                                                    </Link>
                                                                </td>
                                                                <td className="centrado">
                                                                    <Link
                                                                        to={'/publicaciones/ver/'+obra.id}
                                                                    >
                                                                        {obra.visualizaciones}
                                                                    </Link>
                                                                </td>
                                                                <td className="centrado">
                                                                    <Link
                                                                        to={'/publicaciones/ver/'+obra.id}
                                                                    >
                                                                        {obra.precio}
                                                                    </Link>
                                                                </td>
                                                                <td className="centrado">
                                                                    <Link
                                                                        to={'/publicaciones/ver/'+obra.id}
                                                                    >
                                                                        {obra.status}
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table> :
                                                <div className="sin-contenido">
                                                    Aquí irán listados tus distintas obras en borrador, para que puedas irlos trabajando y guardando. Cuando estén listos, mándalos a revisión.
                                                </div>
                                            }
                                        </div>
                                        <div id="mensajes-editor" className="bloque-contenido">
                                            <h2>Mensajes del editor</h2>
                                            { data.mensajes.length > 0 ?
                                                <div>
                                                    <table>
                                                        <tbody>
                                                            {data.mensajes.map(mensaje => (
                                                                <tr key={"mensaje-"+mensaje.id}>
                                                                    <td>
                                                                        <button className="btn-mensaje transparente block" onClick={() => {muestraMensaje(mensaje)}}>
                                                                            {
                                                                                mensaje.sin_leer > 0 ?
                                                                                <span class="sin-leer">{mensaje.sin_leer}</span>
                                                                                :
                                                                                null
                                                                            } {mensaje.subject}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {/* <Link
                                                        to={'/publicaciones'}
                                                        className="ver-mas"
                                                    >
                                                        Ver todos
                                                    </Link> */}
                                                </div>
                                                :
                                                <div className="mensaje-vacio">No tienes ningún mensaje.</div>
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="bloque-publicaciones">
                                        <div id="cursando" className="bloque-contenido">
                                            <h2>Cursando</h2>
                                            { data.cursando !== undefined ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Curso</th>
                                                            <th className="centrado">Horas</th>
                                                            <th className="centrado">Estado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.cursando.map(cursando => (
                                                            <tr key={"cursando-"+cursando.id}>
                                                                <td>{cursando.id}</td>
                                                                <td>{cursando.curso}</td>
                                                                <td className="centrado">{cursando.horas}</td>
                                                                <td className="centrado">{cursando.estado}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table> :
                                                null
                                            }
                                        </div>

                                        <div id="cursos-talleres" className="bloque-contenido">
                                            <h2>Cursos y talleres</h2>
                                            { data.cursos !== undefined ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre del curso o taller</th>
                                                            <th className="centrado">Precio</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.cursos.map(curso => (
                                                            <tr key={"curso-"+curso.id}>
                                                                <td>{curso.nombre}</td>
                                                                <td className="centrado">{curso.precio}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table> :
                                                null
                                            }
                                        </div>
                                    </div> */}
                                </div>
                            </Fragment>
                        }
                    </div>
                </Route>
                <Route exact path={`${path}/agregar`}>
                    <AgregarPublicacion></AgregarPublicacion>
                </Route>
                <Route path={`${path}/ver/:id`}>
                    <Publicacion
                        updateStatus={updateStatus}
                        eliminarObra={eliminarObra}
                    />
                </Route>
            </Switch>
            {isOpen && (
                <Lightbox
                    wrapperClassName="pasos-edicion"
                    mainSrc={data.pasos_edicion[photoIndex]}
                    nextSrc={data.pasos_edicion[(photoIndex + 1) % data.pasos_edicion.length]}
                    prevSrc={data.pasos_edicion[(photoIndex + data.pasos_edicion.length - 1) % data.pasos_edicion.length]}
                    onCloseRequest={() => updateisOpen(false)}
                    onMovePrevRequest={() =>
                        updatePhotoIndex((photoIndex + data.pasos_edicion.length - 1) % data.pasos_edicion.length)
                    }
                    onMoveNextRequest={() =>
                        updatePhotoIndex((photoIndex + 1) % data.pasos_edicion.length)
                    }
                />
            )}
        </div>
    );
}

export default Publicaciones;