import $ from "jquery";
import React, {Fragment, useEffect,useState} from 'react'
import { Line } from 'react-chartjs-2';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import 'react-circular-progressbar/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Spinner from './Spinner';
import ReactGA from 'react-ga';
import Alerta from './Alerta';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReactHtmlParser from 'react-html-parser';
import 'bootstrap';
import 'bootstrap/js/src/dropdown.js';
import 'react-summernote/dist/react-summernote.css';
import ReactSummernote from 'react-summernote';

const Ventas = () => {
    let user_data = JSON.parse(localStorage.getItem('co_user_data'));
    const [ventas,updateVentas] = useState([]);
    const [regalias,updateRegalias] = useState(0);
    const [total_vendido,updateTotalVendido] = useState(0);
    const [graph,updateGraph] = useState([]);
    const [recomendaciones,updateRecomenndaciones] = useState([]);
    const [libros_meta,updateLibrosMeta] = useState([]);
    const [depositos,updateDepositos] = useState([]);
    const [planesMkt,updatePlanesMkt] = useState([]);
    const[firstLoad, updateFirstLoad] = useState(true);
    const[photoIndex, updatePhotoIndex] = useState(0);
    const[isOpen, updateisOpen] = useState(false);
    const[niveles, updateNiveles] = useState([]);
    const[delay, updateDelay] = useState(1000);

    const[mostrarPopup, showPopup] = useState(false);
    const[mensaje, updateMensaje] = useState(false);

    /*MENSAJES*/
    const[mensajes,updateMensajes] = useState([]);
    const[mensajeVisible,updateMensajeVisible] = useState(0);
    const[subjectMensaje,updateSubjectMensaje] = useState(0);
    const [mensajesDialog, setMensajesDialog] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const[enviandoRespuesta, updateEnviandoRespuesta] = useState(false);
    const[respuesta, updateRespuesta] = useState("");
    /*MENSAJES*/

    const ocultarPopup = () => {
        showPopup(false)
    }

    useEffect( () => {
        ReactGA.initialize('GTM-5JDTRDH');
        ReactGA.pageview(window.location.pathname + window.location.search);
        const interval = setInterval(() => {
            apiCall();
        }, delay);

        return () => clearInterval(interval);
    });

    const formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
    });

    const settings_slider = {
        arrows: false,
        dots: true,
        infinite: false,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
    }
      

    const apiCall = async () => {
        updateDelay(60000);
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/ventas/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            return;
        }
        updateNiveles(response.niveles);
        updateVentas(response.obras);
        updateRegalias(response.regalias);
        updateTotalVendido(response.total_vendido);
        updateGraph(response.grafica);
        updateLibrosMeta(response.libros_meta);
        updateDepositos(response.depositos);
        updatePlanesMkt(response.planes);
        updateMensajes(response.mensajes);
        updateFirstLoad(false);
    }

    const data = {
        labels: graph[0],
        datasets: [
          {
            label: '$ Ventas',
            data: graph[1],
            fill: false,
            backgroundColor: 'rgb(0,0,0)',
            borderColor: 'rgba(0,0,0)',
            borderWidth: 5
          },
        ],
      };
      
      const options = {
        scales: {
            xAxes: [{
                gridLines: {
                    lineWidth: 0
                }
            }],
            yAxes: [{
                gridLines: {
                    lineWidth: 0
                }   
            }]
        }
    };

    const ayudaMetas = () => {
        updateisOpen(true);
    }

    /* MENSAJES */
    const handleClose = () => {
        setOpenDialog(false);
        updateEnviandoRespuesta(false);
        updateRespuesta("");
    };

    const muestraMensaje = (mensaje) => {
        updateMensajeVisible(mensaje.id);
        updateSubjectMensaje(mensaje.subject)
        setMensajesDialog(mensaje.mensajes);
        setOpenDialog(true);
        marcarLeido(mensaje.id);
    }

    const marcarLeido = async (mensaje_id) => {
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('mensaje_id',mensaje_id);
        formdata.append('tipo',2);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/marcarleido/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            return;
        }

        const response = await api.json();
        if(response.success){
            updateMensajes(response.mensajes);
        }
    }

    const initEditor = () => {
        $(".dropdown-toggle").dropdown();
        const editArea = document.querySelector(".note-editable");
    }

    const responderMensaje = async () => {
        if(respuesta.trim() === ""){
            updateMensaje("Debes de ingresar una respuesta antes de envíar.");
            showPopup(true);
            return;
        }
        updateEnviandoRespuesta(true);

        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('respuesta',respuesta.trim());
        formdata.append('mensaje_id',mensajeVisible);
        formdata.append('tipo',2);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/guardarrespuesta/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje(api.status+': Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        
        if(response.success){
            updateMensajes(response.mensajes);
            let mensajes = response.mensajes.filter((mensaje) => mensaje.id === mensajeVisible);
            setMensajesDialog(mensajes[0].mensajes);
            updateRespuesta("");
            ReactSummernote.reset()
        }else{
            updateMensaje(response.mensaje);
            showPopup(true);
        }
        
        updateEnviandoRespuesta(false);
    }
    /* MENSAJES */ 

    return (
        <div id="main-container" className="container">
            <Alerta
                mensaje={mensaje}
                mostrarPopup={mostrarPopup}
                ocultar={ocultarPopup}
            />
            <div>
                {
                    firstLoad ?
                        <Spinner></Spinner>
                    :
                    <Fragment>
                        <Dialog
                            maxWidth="md"
                            onClose={handleClose}
                            open={openDialog}>
                            <div className="titulo-dialogo">
                                <h3>{ subjectMensaje }</h3>
                                <IconButton aria-label="close" onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div className="contenido-dialogo">
                                <div id="mensajes">
                                    {mensajesDialog.map(mensaje => (
                                        <Fragment>
                                            <div className={"datos-mensaje mensaje-historial "+(mensaje.autor_mensaje === 1 ? 'admin' : 'autor')}>
                                                <div>
                                                    <span className="fecha-mensaje">{ mensaje.fecha } |</span>
                                                    {
                                                        mensaje.autor_mensaje === 1 ?
                                                            <span className="rol-usuario admin">Administrador</span>
                                                        :
                                                            <span className="rol-usuario autor">Autor</span>
                                                    }
                                                </div>
                                                <div>{ ReactHtmlParser(mensaje.texto) }</div>
                                            </div>
                                            <div className="clear"></div>
                                        </Fragment>
                                    ))}
                                </div>
                                <ReactSummernote
                                        value=""
                                        options={{
                                            height: 100,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ['style', ['bold', 'italic', 'underline', 'clear']]
                                            ]
                                        }}
                                        onChange={updateRespuesta}
                                        onInit={initEditor}
                                    />
                                {
                                    enviandoRespuesta ? 
                                        <Spinner clase="spinner-small"></Spinner>
                                    :
                                        <button className="btn btn-co" id="enviar-respuesta" onClick={responderMensaje}>
                                            Responder
                                        </button>
                                }
                            </div>
                        </Dialog>
                        <div className="bloque-publicaciones">
                            <div id="bloque-ventas" className="bloque-contenido">
                                { ventas !== undefined ?
                                    <Fragment>
                                        <h2>Ventas del mes actual </h2>
                                        {
                                            ventas.length > 0 ?
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Última fecha de compra</th>
                                                        <th>Título</th>
                                                        <th className="centrado">Nivel</th>
                                                        <th className="centrado">Visualizaciones</th>
                                                        <th className="centrado">Libros</th>
                                                        <th className="centrado">Cuentos</th>
                                                        <th className="centrado">Precio</th>
                                                        <th className="centrado">Regalías</th>
                                                        <th className="centrado">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ventas.map(venta => (
                                                        <tr key={"venta-"+venta.id}>
                                                            <td>
                                                                {venta.fecha}
                                                            </td>
                                                            <td>
                                                                {venta.nombre}
                                                            </td>
                                                            <td className="centrado">
                                                                {venta.nivel}
                                                            </td>
                                                            <td className="centrado">
                                                                {venta.visualizaciones}
                                                            </td>
                                                            <td className="centrado">
                                                                {venta.libros}
                                                            </td>
                                                            <td className="centrado">
                                                                {venta.cuentos}
                                                            </td>
                                                            <td className="centrado">
                                                                { formatter.format(venta.precio) }
                                                            </td>
                                                            <td className="centrado">
                                                                { formatter.format(venta.regalias) }
                                                            </td>
                                                            <td className="centrado">
                                                                { formatter.format(venta.total)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    <tr id="row-total">
                                                        <td colSpan="6"></td>
                                                        <th>Total</th>
                                                        <td>{ formatter.format(regalias) }</td>
                                                        <td>{ formatter.format(total_vendido) }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            :
                                            <div className="sin-contenido">
                                                Todavía no tienes libros aprobados. Una vez que pasen la revisión, aparecerán acá y podrás ver las ventas mes a mes.
                                            </div>
                                        }
                                        
                                        <div id="grafica-ventas">
                                            <h2>Histórico Mensual</h2>
                                            <Line
                                                id='grafica'
                                                height="80"
                                                data={data}
                                                options={options}
                                            />
                                        </div>
                                        {/* <div id="recomendaciones-comerciales">
                                            <h2>Recomendaciones Comerciales</h2>
                                            {
                                                recomendaciones.length > 0 ?
                                                    "RECOMENDACIONES"
                                                :
                                                    "No hay recomendaciones"
                                            }
                                        </div> */}
                                    </Fragment>
                                    :
                                    null
                                }
                            </div>
                            <div id="bloque-mkt" className="bloque-contenido">
                                <div id="metas-comerciales">
                                    <h2>
                                        Metas comerciales
                                        <button className="ayuda" onClick={ayudaMetas}>?</button>
                                    </h2>
                                    {
                                        libros_meta.length > 0 ?
                                            <Slider {...settings_slider}>
                                                {libros_meta.map( libro => (
                                                    <div className="slide-meta" key={"metalibro"+libro.titulo}>
                                                        <div className="titulo-meta">{ libro.titulo }</div>
                                                        <CircularProgressbarWithChildren value={libro.vendidos} strokeWidth="10">
                                                            <div className="texto-unidades">Unidades vendidas<span>este año</span></div>
                                                            <div className="numero-vendidas">{libro.vendidos}</div>
                                                        </CircularProgressbarWithChildren>
                                                        <div className="nivel-libro">
                                                            <div className="nivel">Nivel {libro.nivel}<span>Ahora estás aquí</span></div>
                                                            <div className="descripcion-nivel">
                                                                Vende más de {libro.ventas_necesarias} libros antes del 31/12/{new Date().getFullYear()} para pasar a nivel {parseInt(libro.nivel)+1}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        : 'No hay libros'
                                    }
                                </div>
                                <div id="mensajes-ventas" className="bloque-contenido">
                                    <h2>Mensajes</h2>
                                    { mensajes.length > 0 ?
                                        <div>
                                            <table>
                                                <tbody>
                                                    {mensajes.map(mensaje => (
                                                        <tr key={"mensaje-"+mensaje.id}>
                                                            <td>
                                                                <button className="btn-mensaje transparente block" onClick={() => {muestraMensaje(mensaje)}}>
                                                                {
                                                                    mensaje.sin_leer > 0 ?
                                                                    <span class="sin-leer">{mensaje.sin_leer}</span>
                                                                    :
                                                                    null
                                                                } {mensaje.subject}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <div className="mensaje-vacio">No tienes ningún mensaje.</div>
                                    }
                                </div>
                                {/* <div id="reportes">
                                    <h2>Reportes y depósitos</h2>
                                    {
                                        depositos.length > 0 ?
                                        <Fragment>
                                            <table>
                                                    <tbody>
                                                        {depositos.map(deposito => (
                                                            <tr key={"deposito-"+deposito.id}>
                                                                <td>
                                                                    <a className="transparente block" href={deposito.enlace} target="_blank" rel="noreferrer">
                                                                        {deposito.mes}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                        </Fragment>
                                        :
                                            "No hay depositos"
                                    }
                                </div>
                                <div id="planes-mkt">
                                    <h2>Planes Mkt</h2>
                                    {
                                        planesMkt.length > 0 ?
                                        <table>
                                            <tbody>
                                                {planesMkt.map(plan => (
                                                    <tr key={"plan-"+plan.id}>
                                                        <td>
                                                            <button className="transparente block" onClick={() => {alert(plan.nombre)}}>
                                                                {plan.nombre}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        :
                                            "No hay planes"
                                    }
                                </div> */}
                            </div>
                        </div>
                    </Fragment>
                }
                {isOpen && (
                    <Lightbox
                        mainSrc={niveles[photoIndex]}
                        nextSrc={niveles[(photoIndex + 1) % niveles.length]}
                        prevSrc={niveles[(photoIndex + niveles.length - 1) % niveles.length]}
                        onCloseRequest={() => updateisOpen(false)}
                        onMovePrevRequest={() =>
                            updatePhotoIndex((photoIndex + niveles.length - 1) % niveles.length)
                        }
                        onMoveNextRequest={() =>
                            updatePhotoIndex((photoIndex + 1) % niveles.length)
                        }
                    />
                )}
            </div>
        </div>
    )
}

export default Ventas;