import React, {Fragment, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router'
import EditarPublicacion from './EditarPublicacion';
import Capitulos from './Capitulos';
import Spinner from './Spinner';
import ReactGA from 'react-ga';
import Dialog from '@material-ui/core/Dialog';
import Alerta from './Alerta';

const Publicacion = ({updateStatus, eliminarObra}) =>{
    let history = useHistory();
    let user_data = JSON.parse(localStorage.getItem('co_user_data'));
    let { id } = useParams();
    const [publicacion,updatePublicacion] = useState({});
    const[processing, updateProcessing] = useState(false)
    const[edicion, updateEdicion] = useState(false)
    const[options, updateOptions] = useState({})
    const[capitulos, updateCapitulos] = useState([])
    const[permiteEditar, updatePermisoEditar] = useState(false);
    const[firstLoad, updateFirstLoad] = useState(true);
    const[mostrarPopup, showPopup] = useState(false);
    const[eliminando, updateEliminando] = useState(false);

    const[mostrarAlerta, showAlerta] = useState(false);
    const[mensaje, updateMensaje] = useState(false);

    const ocultarPopup = () => {
        showPopup(false)
    }

    useEffect( () => {
        ReactGA.initialize('GTM-5JDTRDH');
        ReactGA.pageview(window.location.pathname + window.location.search);
        apiCall();
    },[]);

    const apiCall = async () => {
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('obra_id',id);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/verpublicacion/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showAlerta(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            return;
        }
        updateCapitulos(response.capitulos)
        updatePublicacion(response.obra);
        updateOptions(response.options);
        if(response.obra.status == 'BOR' || response.obra.status == 'DEC'){
            updatePermisoEditar(true);
        }
        updateFirstLoad(false);
    }

    const eliminarBorrador = async() => {
        updateEliminando(true);
        updatePermisoEditar(false);
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('obra_id',id);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/eliminarborrador/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showAlerta(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateMensaje(response.mensaje);
            showAlerta(true);
            return;
        }
        eliminarObra(id)
        history.push("/publicaciones");
    }

    const handleClose = () => {
        showPopup(false);
    };

    return (
        firstLoad ? 
            <Spinner></Spinner>
        :
        <Fragment>
            <Alerta
                mensaje={mensaje}
                mostrarPopup={mostrarAlerta}
                ocultar={ocultarPopup}
            />
            <Dialog
                maxWidth="md"
                onClose={handleClose}
                open={mostrarPopup}>
                <div className="contenido-dialogo">
                    <div id="texto-confirmacion">
                        Estás apunto de eliminar tu obra. No quedará registro de ella si lo haces.<br/> ¿Deseas continuar?.
                        <div id="botones-confirmacion">
                            {
                                eliminando !== true ?
                                    <Fragment>
                                        <button id="ver-preview" className="btn btn-co" onClick={eliminarBorrador} >Aceptar</button>
                                        <button id="continuar-escribiendo" className="btn"onClick={ () => {showPopup(false)}}>Cancelar</button>
                                    </Fragment>
                                :
                                    <Spinner clase="spinner-small"></Spinner>
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
            { edicion == false ? 
                <div id="datos-publicacion">
                    <div className="container">
                        {
                            permiteEditar ?
                                <div id="contenedor-acciones">
                                    <button id="eliminar-obra" className="btn" onClick={ () => showPopup(true) } >Elminar</button>
                                    <button id="editar-datos" className="btn btn-co" onClick={ () => updateEdicion(true) }>Editar</button>
                                </div>
                            :
                                null
                        }
                        <div id="resumen-datos">
                            <h3>{ publicacion.titulo }</h3>
                        </div>
                        <div id="portada-publicacion">
                            <img src={ publicacion.portada } alt={'Portada: '+publicacion.titulo} title={'Portada: '+publicacion.titulo} />
                        </div>
                        <div id="informacion-publicacion">
                        <div className="informacion">
                            <div className="campo">Categoría:</div>
                            <div className="valor">{ publicacion.categoria }</div>
                        </div>
                        <div className="informacion">
                            <div className="campo">Género:</div>
                            <div className="valor">
                                { publicacion.generos }
                            </div>
                        </div>
                        <div className="informacion">
                            <div className="campo">Título:</div>
                            <div className="valor">{ publicacion.titulo }</div>
                        </div>
                        <div className="informacion">
                            <div className="campo">Sinopsis:</div>
                            <div className="valor">{ publicacion.sinopsis }</div>
                        </div>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div> :
                <EditarPublicacion
                    id={id}
                    options={options}
                    categoria={publicacion.categoria_id}
                    nombre_categoria={publicacion.categoria}
                    generos={publicacion.generos_id.split(',')}
                    nombres_generos={publicacion.generos}
                    titulo={publicacion.titulo}
                    sinopsis={publicacion.sinopsis}
                    publicacion_mostrada={publicacion}
                    updatePublicacionGeneral={updatePublicacion}
                    updateEdicion={updateEdicion}
                ></EditarPublicacion>
            }

            <Capitulos
                obra_id={id}
                capitulos={capitulos}
                sinopsis_lista={ publicacion.sinopsis.trim() != ''}
                updateCapitulos={updateCapitulos}
                permiteEditar={permiteEditar}
                updatePermisoEditar={updatePermisoEditar}
                nombreBloque={publicacion.categoria == 'Cuento' ? 'Cuento' : 'Capítulo'}
                updateStatus={updateStatus}
            ></Capitulos>
        </Fragment>
    )
}

export default Publicacion;