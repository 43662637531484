import React, {useState, Fragment, useEffect} from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom';
import '../css/login.css'
import ReactGA from 'react-ga';
import Alerta from './Alerta';

const Login = ({loginUser}) => {
    const[login,updateLogin] = useState({
        username: '',
        password: ''
    });

    const[recuperarPassword, updateRecuperar] = useState(false);
    const[correoRecuperar, updateCorreo] = useState('');
    const[error, updateError] = useState(false)
    const[processing, updateProcessing] = useState(false)
    const {username, password} = login;
    const[mostrarPopup, showPopup] = useState(false);
    const[mensaje, updateMensaje] = useState(false);

    useEffect( () => {
        ReactGA.initialize('GTM-5JDTRDH');
        ReactGA.pageview(window.location.pathname + window.location.search);
    },[]);

    const updateFields = (e) => {
        updateError(false)
        updateLogin({
            ...login,
            [e.target.name]: e.target.value
        })
    }

    const submitLogin = (e) => {
        e.preventDefault();
        if(username.trim() === '' || password.trim() === ''){
            updateError(true)
            return;
        }
        updateError(false)
        updateProcessing(true)
        apiCall();
    }

    const apiCall = async () => {
        var formdata = new FormData();
        formdata.append('username',username);
        formdata.append('password',password);
        const api = await fetch(
            'https://www.editorialco.com/api/usuarios/loginautor/',
            {
                body: formdata,
                method: 'post'
            }
        );
        if(!api.ok){
            updateProcessing(false)
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateProcessing(false);
            updateMensaje(response.mensaje);
            showPopup(true);
            return;
        }
        updateProcessing(false)
        loginUser({
            'id': response.id,
            'first_name': response.first_name,
            'last_name': response.last_name,
            'email': response.email,
            'auth_key': response.auth_key,
            'primer_login': response.primer_login
        })

    }

    const actualizarCorreo = (e) => {
        updateCorreo(e.target.value)
    }

    const recuperar = () => {
        if(correoRecuperar.trim() === ''){
            updateMensaje('Ingresa un correo para recuperar tu contraseña.');
            showPopup(true);
            return;
        }
        enviarRecuperacion();
    }

    const enviarRecuperacion = async () => {
        updateProcessing(true)
        var formdata = new FormData();
        formdata.append('email',correoRecuperar.trim());
        const api = await fetch(
            'https://www.editorialco.com/api/usuarios/resetPassword/',
            {
                body: formdata,
                method: 'post'
            }
        );
        if(!api.ok){
            updateProcessing(false);
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateProcessing(false);
            updateMensaje(response.mensaje);
            showPopup(true);
            return;
        }
        updateMensaje(response.mensaje);
        showPopup(true);
        updateProcessing(false);
        updateRecuperar(false);
    }

    const ocultarPopup = () => {
        showPopup(false)
    }

    return(
        <div id="login-wrapper">
            <Alerta
                mensaje={mensaje}
                mostrarPopup={mostrarPopup}
                ocultar={ocultarPopup}
            />
            <div className="container container-login">
                <div className="row mt-3" id="contenedor-login">
                    <img 
                        src={"/img/logo.png"} 
                        id="logo"
                        alt="Logo Editorial Cõ"
                        title="Logo Editorial Cõ"
                    />
                    <form
                        method="post"
                        onSubmit={submitLogin}
                    >
                        <input
                            type="text"
                            name="username"
                            id="id_username"
                            className="form-control"
                            placeholder="Usuario"
                            value={username}
                            onChange={updateFields}
                        />
                        <input
                            type="password"
                            name="password"
                            id="id_password"
                            className="form-control"
                            placeholder="Contraseña"
                            value={password}
                            onChange={updateFields}
                        />
                        { error ? <div className="alert alert-danger" role="alert">Campos son obligatorios</div> : null}
                        { processing ? 
                            null : 
                            <Fragment>
                                <button className="btn btn-primary btn-block">Iniciar Sesión</button>
                                <div className="contenedor-enlace">¿Todavía no eres autor? <a href="http://www.autores.editorialco.com/registro" className="opcion-usuario">Regístrate aquí.</a></div>
                                <div>
                                    <button type="button" onClick={() => recuperarPassword ? updateRecuperar(false): updateRecuperar(true)} className="opcion-usuario">¿Olvidaste tu contraseña?</button>
                                    {
                                        recuperarPassword ?
                                        <Fragment>
                                            <input
                                                type="text"
                                                id="correo-recuperar"
                                                placeholder="Correo electrónico"
                                                onChange={actualizarCorreo}
                                            />
                                            <button
                                                type="button"
                                                value={correoRecuperar}
                                                onClick={recuperar}
                                                id="btn-recuperar"
                                            >Recuperar</button>
                                        </Fragment>
                                        :
                                        null
                                    }
                                </div>
                            </Fragment>
                        }
                        { processing ?
                            <div className="spinner">
                                <div className="rect1"></div>
                                <div className="rect2"></div>
                                <div className="rect3"></div>
                                <div className="rect4"></div>
                                <div className="rect5"></div>
                            </div> : 
                            null
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;