import $ from "jquery";
import 'bootstrap';
import 'bootstrap/js/src/dropdown.js';
import 'react-summernote/dist/react-summernote.css';
import React, {Fragment,useState, useEffect} from 'react';
import ReactHtmlParser from 'react-html-parser';
import ReactSummernote from 'react-summernote';
import Spinner from "./Spinner";
import Dialog from '@material-ui/core/Dialog';
import Alerta from "./Alerta";


const Capitulos = ({obra_id,capitulos,sinopsis_lista,updateCapitulos,permiteEditar,updatePermisoEditar, nombreBloque, updateStatus}) => {
    let user_data = JSON.parse(localStorage.getItem('co_user_data'));
    const [editando,updateEditando] = useState(false);
    const [capituloVisible,updatecapituloVisible] = useState(null);
    const [idTitulo, updateIdTitulo] = useState(null);
    const [processing, updateProcessing] = useState(false);
    const [ediciones, updateEdiciones] = useState([]);
    const [lastSaved, updateLastSaved] = useState('');
    const[mostrarPopup, showPopup] = useState(false);
    const[mostrarAlerta, showAlerta] = useState(false);
    const[mensaje, updateMensaje] = useState(false);
    const[confirmEliminar, updateConfirmEliminar] = useState(false);
    const[confirmRevision, updateConfirmRevision] = useState(false);
    const[eliminando, updateEliminando] = useState(false);
    const[capituloEliminar, updateCapituloEliminar] = useState(0);
    
    let nombreCapitulo = "";
    const MINUTE_MS = 10000;

    useEffect(() => {
        const interval = setInterval(() => {
            guardarBorrador();
        }, MINUTE_MS);

        return () => clearInterval(interval);
    });
    

    const autoSave = async () => {
        console.log('AUTOSAVE');
    }
    
    const editarCapitulos = () => {
        if(capitulos.length > 0){
            updatecapituloVisible(capitulos[0]);
        }
        updateEditando(true)
    }

    const switchCapitulo = (e) => {
        let seleccionado = {};
        capitulos.map( capitulo => {
            if(capitulo.id == e.target.dataset.id){
                seleccionado = capitulo;
            }
        });
        updatecapituloVisible(seleccionado);
    }

    const actualizarNombreCapitulo = async (e) => {
        if(nombreCapitulo.trim() =='' || idTitulo === null){
            updateMensaje('El nombre no puede quedar vacío.');
            showAlerta(true);
            return;
        }

        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('capitulo_id',idTitulo);
        formdata.append('nombre',nombreCapitulo);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/actualizartitulocapitulo/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showAlerta(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateMensaje(response.mensaje);
            showAlerta(true);
            return;
        }
        capitulos.map( capitulo => {
            if(capitulo.id == idTitulo){
                capitulo.nombre = nombreCapitulo;
            }
        })
        updateCapitulos(capitulos)
        nombreCapitulo = "";
        updateIdTitulo(null);
    }

    const eliminarCapitulo = async () => {
        updateEliminando(true);
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('capitulo_id',capituloEliminar);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/eliminarcapitulo/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showAlerta(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateMensaje(response.mensaje);
            showAlerta(true);
            return;
        }
        updateCapitulos(capitulos.filter( capitulo => (
            capitulo.id != capituloEliminar
        )));
        updateEliminando(false);
        updateCapituloEliminar(0);
        updateConfirmEliminar(false);
        updateMensaje(response.mensaje);
        showAlerta(true);
    }

    const agregarCapitulo = async () => {
        const nombre = document.getElementById("agrupador").value.trim();
        if(nombre == ''){
            updateMensaje('El nombre del '+nombreBloque+' no puede estár vacío.');
            showAlerta(true);
            return;
        }
        updateProcessing(true);
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('obra_id',obra_id);
        formdata.append('nombre',nombre);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/agregarcapitulo/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showAlerta(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateMensaje(response.mensaje);
            showAlerta(true);
            return;
        }
        document.getElementById("agrupador").value = "";
        updateProcessing(false);
        updateCapitulos([
            ...capitulos,
            response.capitulo
        ]);
        updatecapituloVisible(response.capitulo);
    }

    const guardarBorrador = async (e) => {
        if(ediciones.length < 1){
            if(e !== undefined){
                if(e !== undefined){
                    showPopup(true);
                }
            }
            return;
        }
        updateProcessing(true);
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('obra_id',obra_id);
        formdata.append('capitulos',JSON.stringify(ediciones));
        const api = await fetch(
            'https://www.editorialco.com/api/autor/guardarborrador/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showAlerta(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateMensaje(response.mensaje);
            showAlerta(true);
            return;
        }
        ediciones.map( edicion => {
            capitulos.map( capitulo => {
                if(capitulo.id == edicion[0]){
                    capitulo.texto = edicion[1];
                }
            })
        });
        updateCapitulos(capitulos);
        updateEdiciones([]);
        updateProcessing(false);
        updateLastSaved(new Date().toLocaleString());
        if(e !== undefined){
            showPopup(true);
        }
    }

    const guardaEdicion = (edicion) => {
        let existe = false;
        ediciones.map( capitulo => {
            if(capitulo[0] == capituloVisible.id){
                capitulo[1] = edicion;
                existe = true;
            }
        });
        if(existe){
            updateEdiciones(ediciones);
        }else{
            updateEdiciones([
                ...ediciones,
                [
                    capituloVisible.id,
                    edicion
                ]
            ]);
        }
    }

    const initEditor = () => {
        $(".dropdown-toggle").dropdown();
        const editArea = document.querySelector(".note-editable");
        editArea.innerHTML = capituloVisible.texto;
    }

    const revisaEnter = (e) => {
        if(e.charCode == 13){
            agregarCapitulo();
        }
    }

    const publicar = async() => {
        if(!sinopsis_lista){
            updateMensaje('Debes de agregar una sinopsis a tu obra antes de mandar a revisión.');
            showAlerta(true);
            return;
        }
        updateEliminando(true);
        updateProcessing(true);
        var formdata = new FormData();
        formdata.append('user_id',user_data.id);
        formdata.append('obra_id',obra_id);
        const api = await fetch(
            'https://www.editorialco.com/api/autor/revisionpublicacion/',
            {
                body: formdata,
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Token '+user_data.auth_key
                })
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showAlerta(true);
            updateProcessing(false);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateMensaje(response.mensaje);
            showAlerta(true);
            updateProcessing(false);
            return;
        }
        updateMensaje(response.mensaje);
        showAlerta(true);
        updateProcessing(false);
        updateEliminando(false);
        updateEditando(false);
        updatePermisoEditar(false);
        updateStatus(obra_id);
        updateConfirmRevision(false);
    }

    const handleClose = () => {
        showPopup(false);
        updateConfirmEliminar(false);
        updateConfirmRevision(false);
    };

    const verBorrador = () => {
        updateEditando(false);
        showPopup(false);
    }

    const continuarEscribiendo = () => {
        showPopup(false);
    }

    const ocultarPopup = () => {
        showAlerta(false)
    }

    return(
        <Fragment>
            <Alerta
                mensaje={mensaje}
                mostrarPopup={mostrarAlerta}
                ocultar={ocultarPopup}
            />
            <Dialog
                maxWidth="md"
                onClose={handleClose}
                open={mostrarPopup}>
                <div className="contenido-dialogo">
                    <div id="texto-confirmacion">
                        Se guardó correctamente.
                        <div id="botones-confirmacion">
                            <button id="ver-preview" className="btn btn-co" onClick={verBorrador}>Ver borrador</button>
                            <button id="continuar-escribiendo" className="btn" onClick={continuarEscribiendo}>Continuar escribiendo</button>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                maxWidth="md"
                onClose={handleClose}
                open={confirmEliminar}>
                <div className="contenido-dialogo">
                    <div id="texto-confirmacion">
                        {'Se eliminará el '+nombreBloque+' ¿Deseas continuar?'}
                        <div id="botones-confirmacion">
                            {
                                eliminando ?
                                    <Spinner clase="spinner-small"></Spinner>
                                : 
                                    <Fragment>
                                        <button id="ver-preview" className="btn btn-co" onClick={eliminarCapitulo}>Aceptar</button>
                                        <button id="continuar-escribiendo" className="btn" onClick={handleClose}>Cancelar</button>
                                    </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                maxWidth="md"
                onClose={handleClose}
                open={confirmRevision}>
                <div className="contenido-dialogo">
                    <div id="texto-confirmacion">
                        Una vez mandada a revisión no se podrá editar la obra. ¿Continuar?
                        <div id="botones-confirmacion">
                            {
                                eliminando ?
                                    <Spinner clase="spinner-small"></Spinner>
                                : 
                                    <Fragment>
                                        <button id="ver-preview" className="btn btn-co" onClick={publicar}>Aceptar</button>
                                        <button id="continuar-escribiendo" className="btn" onClick={handleClose}>Cancelar</button>
                                    </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
            <div id="preview-capitulos" key="preview-capitulos">
                <div className="container">
                    { !editando && permiteEditar? 
                        <Fragment>
                            <button id="editar-capitulos" className="btn btn-co" onClick={editarCapitulos}>
                                Editar
                            </button>
                            <div className="clear"></div>
                        </Fragment>
                        :
                        null
                    }
                    {
                        editando ? 
                            null
                        :
                            capitulos.length > 0 ?
                                capitulos.map( capitulo => (
                                    <div className="capitulo" key={'capitulo-'+capitulo.id}>
                                        <div className="separador"></div>
                                        <h2>{ capitulo.nombre }</h2>
                                        {  ReactHtmlParser(capitulo.texto) }
                                    </div>
                                ))
                            :
                                <div className="capitulo" key="capitulo-vacio">
                                    <div className="separador"></div>
                                    <div id="sin-capitulos">No hay {nombreBloque}s</div>
                                </div>
                    }
                </div>
            </div>
            {
                editando ?
                    <div id="bloque-capitulos" key="bloque-capitulos">
                        <div className="container">
                            <div className="separador"></div>
                            <div id="textos-capitulos" key="texto-capitulos">
                                { capituloVisible != null ?
                                    <div className="bloque-agrupador" id={"capitulo-"+capituloVisible.id} data-capitulo={ capituloVisible.id } key={"textocapitulo-"+capituloVisible.id}>
                                        <div className="nombre-agrupador">{ capituloVisible.nombre }</div>
                                        {/* <textarea
                                            className="agrupador-texto"
                                            id={"texto-"+capituloVisible.id}
                                            defaultValue={capituloVisible.texto}
                                            onChange={guardaEdicion}
                                            ></textarea> */}
                                        <Fragment>
                                            <ReactSummernote
                                                value={capituloVisible.texto}
                                                options={{
                                                    height: 350,
                                                    dialogsInBody: true,
                                                    toolbar: [
                                                        ['style', ['bold', 'italic', 'underline', 'clear']],
                                                        ['para', ['paragraph']],
                                                        ['insert',['picture']],
                                                        ['table', ['table']],
                                                    ]
                                                }}
                                                onChange={guardaEdicion}
                                                onInit={initEditor}
                                            />
                                            {
                                                lastSaved !== '' ?
                                                <div id="last-saved">Última vez que se guardó: {lastSaved}</div>
                                                :
                                                null
                                            }
                                        </Fragment>
                                    </div>
                                    : 
                                    null
                                }
                            </div>
                            <div id="listado-capitulos" key="listado-capitulos">
                                <div className="titulo">
                                    <span className="agrupador">
                                        {nombreBloque}s
                                    </span>
                                </div>
                                <div id="nuevo-agrupador">
                                    {
                                        processing == false ?
                                            <button
                                                id="agregar-agrupador"
                                                className="btn btn-co"
                                                onClick={agregarCapitulo}>
                                                    + Agregar <span className="agrupador">{nombreBloque}</span>
                                                </button>
                                        :
                                            null
                                    }
                                    <input type="text" id="agrupador" placeholder={"Ingresa un nombre para el "+nombreBloque+"."} onKeyPress={revisaEnter} />
                                </div>
                                <div id="agrupadores">
                                    {
                                        capitulos.map( capitulo => (
                                            <div className="opcion-agrupador" data-id={ capitulo.id } data-orden={ capitulo.numero }>
                                                {
                                                    idTitulo !== null && idTitulo == capitulo.id ?
                                                        <div className="editar-agrupador-lista" id={"editar-"+capitulo.id} key={"agrupador-lista"+capitulo.id}>
                                                            <input
                                                                type="text"
                                                                className="campo-editar-nombre"
                                                                id={"nombre-editado-"+capitulo.id}
                                                                defaultValue={capitulo.nombre}
                                                                onChange={(e) => { nombreCapitulo = e.target.value }}
                                                                />
                                                            <button
                                                                className="guardar-nombre"
                                                                data-id={capitulo.id}
                                                                onClick={actualizarNombreCapitulo}
                                                            >
                                                                <img src="/img/guardar.png"></img>
                                                            </button>
                                                            <div className="clear"></div>
                                                        </div>
                                                        :
                                                        <div className="nombre-agrupador-lista" id={"nombre-"+capitulo.id} key={"agrupador-lista"+capitulo.id}>
                                                            <button className="seleccionar-agrupador" data-id={ capitulo.id } onClick={switchCapitulo}>
                                                                {capitulo.nombre}
                                                            </button>
                                                            <button className="eliminar-capitulo"  data-id={ capitulo.id } onClick={ () => {updateConfirmEliminar(true);updateCapituloEliminar(capitulo.id)}} title={"Borrar "+nombreBloque.toLowerCase()}>
                                                                <img
                                                                    data-id={ capitulo.id }
                                                                    src={"/img/eliminar.png"}
                                                                />
                                                            </button>
                                                            <button className="editar-nombre" data-id={ capitulo.id } onClick={ (e) => {updateIdTitulo(parseInt(e.target.dataset.id))} } title={"Cambiar nombre a "+nombreBloque.toLowerCase()} >
                                                                <img
                                                                    data-id={ capitulo.id }
                                                                    src={"/img/editar.png"}
                                                                />
                                                            </button>
                                                        </div>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="clear" key="clear-123123123"></div>
                            <div id="acciones-publicacion" key="acciones-publicacion">
                                {
                                    processing ?
                                    <Spinner clase="spinner-small"></Spinner>
                                    :
                                    <Fragment>
                                        <button id="guardar-capitulos" className="btn btn-co" onClick={guardarBorrador}>Guardar Borrador</button>
                                        <button id="confirmar-publicar" className="btn btn-co" onClick={ () => {updateConfirmRevision(true)}}>Revisión</button>
                                        <div className="clear"></div>
                                    </Fragment>
                                }
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                :
                    null
            }
        </Fragment>
    );
}

export default Capitulos;