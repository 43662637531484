import React, {Fragment, useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import ReactGA from 'react-ga';
import Alerta from './Alerta';

const Registro = ({loginUser}) => {
    const[firstLoad, updateFirstLoad] = useState(true);
    const[processing, updateProcessing] = useState(false);
    const[user, updateUser] = useState('');
    const[passwordMatch, updatePassword] = useState(true);
    const[mostrarPassword, updateMostrarPassword] = useState(false);
    const[datos, updateDatos] = useState({
        'correo': '',
        'password': '',
        'confirmar_password': '',
        'pseudonimo': '',
        'nombre': '',
        'ap': '',
        'am': '',
        'sexo': '',
        'dia': '',
        'mes': '',
        'anio': '',
        'estado': '',
        'municipio': '',
        'calle': '',
        'exterior': '',
        'interior': '',
        'facebook': '',
        'instagram': '',
        'twitter': '',
        'youtube': '',
    });

    const[estados,updateEstados] = useState([]);
    const[municipios,updateMunicipios] = useState([]);
    const[mostrarPopup, showPopup] = useState(false);
    const[mensaje, updateMensaje] = useState(false);

    const ocultarPopup = () => {
        showPopup(false)
    }

    useEffect( () => {
        let user_data = JSON.parse(localStorage.getItem('co_user_data'));
        if(user_data !== null && user_data.auth_key !== undefined){
            updateUser(user_data.first_name+' '+user_data.last_name);
        }else{
            ReactGA.initialize('GTM-5JDTRDH');
            ReactGA.pageview(window.location.pathname + window.location.search);
            apiCall();
        }
    },[]);

    const apiCall = async () => {
        var formdata = new FormData();
        const api = await fetch(
            'https://www.editorialco.com/api/registro/estados/',
            {
                body: formdata,
                method: 'post'
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateMensaje(response);
            showPopup(true);
            return;
        }

        updateEstados(response.estados);
        updateFirstLoad(false);
    }

    const archivoSeleccionado = (e) => {
        updateDatos({
            ...datos,
            [e.target.name]: e.target.files[0]
        });
    }

    const updateFields = (e) => {
        updateDatos({
            ...datos,
            [e.target.name]: e.target.value
        })
    }

    const updateEstadoMunicipios = async (e) => {
        updateDatos({
            ...datos,
            ['estado']: e.target.value
        });
        var formdata = new FormData();
        formdata.append('estado_id',e.target.value);
        const api = await fetch(
            'https://www.editorialco.com/api/registro/municipios/',
            {
                body: formdata,
                method: 'post'
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            return;
        }
        const response = await api.json();
        if(!response.success){
            return;
        }
        updateMunicipios(response.municipios)
    }

    const registrar = async () => {
        if(datos.correo.trim() == '' || datos.password.trim() == '' ||
        datos.pseudonimo.trim() == '' || datos.nombre.trim() == '' || datos.ap.trim() == '' || datos.am.trim() == '' ||
        datos.sexo == '' || datos.dia == '' || datos.mes == '' || datos.anio == '' ||
        datos.estado == '' || datos.municipio == '' || datos.calle.trim() == '' || datos.exterior.trim() == ''){
            updateMensaje('Todos los campos son obligatorios.');
            showPopup(true);
            return;
        }
        if(datos.password.trim().length < 6){
            updateMensaje('La contraseña debe tener un mínimo de 6 caracteres.');
            showPopup(true);
            return;
        }

        let redes = 0;
        if(datos.facebook != null && datos.facebook.trim().length > 0){
            redes ++;
        }
        if(datos.instagram != null && datos.instagram.trim().length > 0){
            redes ++;
        }
        if(datos.twitter != null && datos.twitter.trim().length > 0){
            redes ++;
        }
        if(datos.youtube != null && datos.youtube.trim().length > 0){
            redes ++;
        }
        if(redes < 2){
            updateMensaje('Debes de ingresar por lo menos dos de tus redes sociales.');
            showPopup(true);
            return;
        }

        updateProcessing(true);

        var formdata = new FormData();
        formdata.append('pseudonimo',datos.pseudonimo);
        formdata.append('nombre',datos.nombre);
        formdata.append('ap',datos.ap);
        formdata.append('am',datos.am);
        formdata.append('sexo',datos.sexo);
        formdata.append('dia',datos.dia);
        formdata.append('mes',datos.mes);
        formdata.append('anio',datos.anio);
        formdata.append('estado',datos.estado);
        formdata.append('municipio',datos.municipio);
        formdata.append('correo',datos.correo);
        formdata.append('password',datos.password);
        formdata.append('calle',datos.calle);
        formdata.append('exterior',datos.exterior);
        formdata.append('interior',datos.interior);
        if(datos.facebook == null){
            formdata.append('facebook','');
        }else{
            formdata.append('facebook',datos.facebook);
        }
        if(datos.instagram == null){
            formdata.append('instagram','');
        }else{
            formdata.append('instagram',datos.instagram);
        }
        if(datos.twitter == null){
            formdata.append('twitter','');
        }else{
            formdata.append('twitter',datos.twitter);
        }
        if(datos.youtube == null){
            formdata.append('youtube','');
        }else{
            formdata.append('youtube',datos.youtube);
        }

        const api = await fetch(
            'https://www.editorialco.com/api/registro/registrar/',
            {
                body: formdata,
                method: 'post'
            }
        );
        if(!api.ok){
            updateMensaje('Ocurrió un error al comunicarse con el servidor.');
            showPopup(true);
            updateProcessing(false);
            return;
        }
        const response = await api.json();
        if(!response.success){
            updateMensaje(response.mensaje);
            showPopup(true);
            updateProcessing(false);
            return;
        }

        loginUser({
            'id': response.id,
            'first_name': datos.nombre,
            'last_name': datos.ap.trim()+' '+datos.am.trim(),
            'email': datos.correo.trim(),
            'auth_key': response.auth_key,
            'primer_login': 1
        })
        updateProcessing(false);
    }

    const checkPassword = (e) => {
        updateDatos({
            ...datos,
            ['confirmar_password']: e.target.value
        })

        if(e.target.value !== datos.password){
            updatePassword(false);
        }else{
            updatePassword(true);
        }
    }

    const switchPassword = (e) => {
        e.preventDefault();
        if(mostrarPassword === true)
            updateMostrarPassword(false);
        else
            updateMostrarPassword(true);
    }

    var anios = []
    for(var i = (parseInt(new Date().getFullYear())-80); i <= (parseInt(new Date().getFullYear())-10); i++) {
        anios.push(i);
    }

    return(
        <div id="registro">
            <Alerta
                mensaje={mensaje}
                mostrarPopup={mostrarPopup}
                ocultar={ocultarPopup}
            />
            <img
                src={"img/medio_circulo.png"}
                alt="Medio Círculo"
                title="Medio Círculo"
                id="medio-circulo"
            />

            <img
                src={"img/leyendo.png"}
                alt="Joven Leyendo"
                title="Joven Leyendo"
                id="leyendo"
            />
            {user !== '' ?
                <div id="mensaje-registro">
                    Has iniciado sesión como <span>{user}.</span>
                    <br/>
                    <Link to={'/perfil'} className="btn btn-co" id="ir-perfil">
                        Ir a tu perfil
                    </Link>
                </div>
            :
                <Fragment>
                    <div id="main-container" className="container">
                        {
                            firstLoad ?
                                <Spinner></Spinner>
                            :
                            <Fragment>
                                {
                                    <div id="formato-registro">
                                        <h1 id="titulo-registro">Formulario de registro</h1>
                                        <div className="subtitulo">Regístrate para crear un perfil</div>
                                        <form>
                                            <div id="datos-cuenta">
                                                <div className="agrupador-registro">
                                                    Datos personales
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="correo-perfil">Correo electrónico</label>
                                                    <input
                                                        type="text"
                                                        name="correo"
                                                        id="correo-perfil"
                                                        placeholder="Correo electrónico"
                                                        value={datos.correo}
                                                        onChange={updateFields} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="password-perfil">Contraseña</label>
                                                    <div className="password-wrapper">
                                                        <input
                                                            type={ mostrarPassword ? 'text' : 'password' }
                                                            name="password"
                                                            id="password-perfil"
                                                            placeholder="Contraseña"
                                                            value={datos.password}
                                                            onChange={updateFields} />
                                                        <button onClick={switchPassword}>
                                                            <img
                                                                src={"img/eye.png"}
                                                                alt="Ver Contraseña"
                                                                title="Ver Contraseña"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div className="password-wrapper confirmacion">
                                                        <input
                                                            type={ mostrarPassword ? 'text' : 'password' }
                                                            name="confirmar_password"
                                                            id="confirma_password-perfil"
                                                            placeholder="Confirmar Contraseña"
                                                            value={datos.confirmar_password}
                                                            onChange={checkPassword} />
                                                    </div>
                                                    {
                                                        passwordMatch === false ?
                                                        <div class="alert alert-danger" role="alert" id="no-match">
                                                            Las contraseñas no coinciden.
                                                        </div>:
                                                        null
                                                    }
                                                </div>
                                            </div>


                                            {/* <input type="file" name="foto" onChange={archivoSeleccionado} /> */}
                                            <div id="datos-personales">
                                                <div className="form-group" id="bloque-nombre">
                                                    <label htmlFor="nombre-perfil">Nombre Legal</label>
                                                    <span>Este es el nombre que aparece en tu documento oficial. Puede ser Licencia o Pasaporte</span>
                                                    <input
                                                        type="text"
                                                        name="nombre"
                                                        id="nombre-perfil"
                                                        placeholder="Nombre"
                                                        value={datos.nombre}
                                                        onChange={updateFields} />
                                                    <input
                                                        type="text"
                                                        name="ap"
                                                        id="ap-perfil"
                                                        placeholder="Apellido Paterno"
                                                        value={datos.ap}
                                                        onChange={updateFields} />
                                                    <input
                                                        type="text"
                                                        name="am"
                                                        id="am-perfil"
                                                        placeholder="Apellido Materno"
                                                        value={datos.am}
                                                        onChange={updateFields} />
                                                    <div className="clear"></div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="pseudonimo-perfil">Pseudónimo</label>
                                                    <span>Es el nombre que aparecerá en la portada de los libros</span>
                                                    <input
                                                        type="text"
                                                        name="pseudonimo"
                                                        id="pseudonimo-perfil"
                                                        placeholder="Pseudónimo"
                                                        value={datos.pseudonimo}
                                                        onChange={updateFields} />
                                                </div>
                                                
                                                <div className="form-group">
                                                    <label htmlFor="sexo-perfil">Sexo</label>
                                                    <select id="sexo-perfil" name="sexo" onChange={updateFields} value={datos.sexo}>
                                                        <option value="">Sexo</option>
                                                        <option value="1">Hombre</option>
                                                        <option value="2">Mujer</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="dia-perfil">Fecha de Nacimiento</label>
                                                    <div className="clear"></div>
                                                    <select id="dia-perfil" name="dia" onChange={updateFields} value={datos.dia}>
                                                        <option value=""></option>
                                                        <option value="01" >01</option>
                                                        <option value="02" >02</option>
                                                        <option value="03" >03</option>
                                                        <option value="04" >04</option>
                                                        <option value="05" >05</option>
                                                        <option value="06" >06</option>
                                                        <option value="07" >07</option>
                                                        <option value="08" >08</option>
                                                        <option value="09" >09</option>
                                                        <option value="10" >10</option>
                                                        <option value="11" >11</option>
                                                        <option value="12" >12</option>
                                                        <option value="13" >13</option>
                                                        <option value="14" >14</option>
                                                        <option value="15" >15</option>
                                                        <option value="16" >16</option>
                                                        <option value="17" >17</option>
                                                        <option value="18" >18</option>
                                                        <option value="19" >19</option>
                                                        <option value="20" >20</option>
                                                        <option value="21" >21</option>
                                                        <option value="22" >22</option>
                                                        <option value="23" >23</option>
                                                        <option value="24" >24</option>
                                                        <option value="25" >25</option>
                                                        <option value="26" >26</option>
                                                        <option value="27" >27</option>
                                                        <option value="28" >28</option>
                                                        <option value="29" >29</option>
                                                        <option value="30" >30</option>
                                                        <option value="31" >31</option>
                                                    </select>
                                                    <select id="mes-perfil" name="mes" onChange={updateFields} value={datos.mes}>
                                                        <option value=""></option>
                                                        <option value="01" >Enero</option>
                                                        <option value="02" >Febrero</option>
                                                        <option value="03" >Marzo</option>
                                                        <option value="04" >Abril</option>
                                                        <option value="05" >Mayo</option>
                                                        <option value="06" >Junio</option>
                                                        <option value="07" >Julio</option>
                                                        <option value="08" >Agosto</option>
                                                        <option value="09" >Septiempre</option>
                                                        <option value="10" >Octubre</option>
                                                        <option value="11" >Noviembre</option>
                                                        <option value="12" >Diciembre</option>
                                                    </select>
                                                    <select id="anio-perfil" name="anio" onChange={updateFields} value={datos.anio}>
                                                        <option value=""></option>
                                                        {
                                                            anios.map(anio => (
                                                                <option value={anio} key={'anio-'+anio.toString()}>{anio}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <div className="clear"></div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="estado-perfil">Domicilio</label>
                                                    <div className="clear"></div>
                                                    <input type="text" className="" id="pais-perfil" value="México" disabled />
                                                    <select id="estado-perfil" name="estado" onChange={updateEstadoMunicipios} value={datos.estado}>
                                                        <option value="">Estado</option>
                                                        {
                                                            estados.map(estado => (
                                                                <option value={estado.id} key={'estado-'+estado.id.toString()}>{estado.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <select id="municipio-perfil" name="municipio" onChange={updateFields} value={datos.municipio}>
                                                        <option>Municipio/Alcaldía</option>
                                                        {
                                                            municipios.map(municipio => (
                                                                <option value={municipio.id} key={'municipio-'+municipio.id.toString()}>{municipio.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <div className="clear"></div>
                                                    <input
                                                        type="text"
                                                        name="calle"
                                                        id="calle-perfil"
                                                        placeholder="Calle"
                                                        value={datos.calle}
                                                        onChange={updateFields} />
                                                    <input
                                                        type="text"
                                                        name="exterior"
                                                        id="exterior-perfil"
                                                        placeholder="Número Exterior"
                                                        value={datos.exterior}
                                                        onChange={updateFields} />
                                                    <input
                                                        type="text"
                                                        name="interior"
                                                        id="interior-perfil"
                                                        placeholder="Número Interior"
                                                        value={datos.interior}
                                                        onChange={updateFields} />
                                                    <div className="clear"></div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Redes Sociales</label>
                                                    <input className="input-red" type="text" name="facebook" id="facebook" placeholder="Facebook" value={datos.facebook} onChange={updateFields} />
                                                    <input className="input-red" type="text" name="instagram" id="instagram" placeholder="Instagram" value={datos.instagram} onChange={updateFields} />
                                                    <input className="input-red" type="text" name="twitter" id="twitter" placeholder="Twitter" value={datos.twitter} onChange={updateFields} />
                                                    <input className="input-red" type="text" name="youtube" id="youtube" placeholder="Youtube" value={datos.youtube} onChange={updateFields} />
                                                    <div className="clear"></div>
                                                </div>
                                            </div>

                                        </form>
                                        {
                                            processing ?
                                                <Spinner clase="spinner-small"></Spinner>
                                            :
                                                <Fragment>
                                                    <button className="btn btn-co" id="btn-registrar" onClick={registrar}>Guardar</button>
                                                    <div className="clear"></div>
                                                </Fragment>
                                        }
                                    </div>
                                }
                            </Fragment>
                        }
                    </div>
                </Fragment>
            }
        </div>
    );
}

export default Registro;